const QUERY_CACHE_KEY = '__initial_query__';

function getInitialQuery() {
  return sessionStorage.getItem(QUERY_CACHE_KEY);
}

$(() => {
  const isLandedFromOtherSite = !document.referrer.includes(document.domain);
  if (!isLandedFromOtherSite) {
    return;
  }

  sessionStorage.setItem(QUERY_CACHE_KEY, location.search);
});
